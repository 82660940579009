import { render, staticRenderFns } from "./_OnceDailyInitialDose.vue?vue&type=template&id=7112a17c&scoped=true"
import script from "./_OnceDailyInitialDose.vue?vue&type=script&lang=js"
export * from "./_OnceDailyInitialDose.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7112a17c",
  null
  
)

export default component.exports