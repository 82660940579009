<template>
  <div data-testid="basal-once-daily-section">
    <v-row>
      <v-col> Prescribe initial <strong>basal</strong> dose</v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="3">
        <DoseInput
          :value="prescription.basal_once_daily.value"
          @input="onInput($event, 'basal_once_daily')"
          @change="onChange($event, 'PRESCRIBE_DOSE:DAILY_DOSE_VALUE')"
          :validation-rules="getValidationRules('basal_once_daily')"
          :dosing-display-unit="dosingDisplayUnit('basal_once_daily')"
          :default-initial-dose="getDefaultInitialDose('basal_once_daily')"
          data-testid="input-basal-once-daily"
          :required-text="'Prescribe initial daily dose is required'"
        >
          Initial <strong>daily</strong> dose
        </DoseInput>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import DoseInput from './_DoseInput'
import initialPrescriptionMixin from './initialPrescriptionMixin'

export default {
  name: 'OnceDailyInitialDose',
  mixins: [initialPrescriptionMixin],
  components: { DoseInput },
}
</script>

<style scoped></style>
